import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/ochrona-danych'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import Events from '../../components/Events'

const files = [
  {
    title: {
      pl: 'Polityka Ochrony Danych Osobowych',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: 'Wersja polska',
      en: 'Polish version',
      ua: 'польський варіант',
    },
    link: '/pdf/podo_pl.pdf',
    targetBlank: true,
  },
  {
    title: {
      pl: 'Polityka Ochrony Danych Osobowych',
      en: 'Personal Data Protection Policy',
      ua: 'Politika Ochrony Данич Особович',
    },
    desc: {
      pl: 'Wersja angielska',
      en: 'English version',
      ua: 'Англомовна версія',
    },
    link: '/pdf/podo_en.pdf',
    targetBlank: true,
  },
]

const OchronaDanych = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/ochrona-danych.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Czytaj',
      en: 'Read',
      ua: 'Прочитайте',
    },
    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Dokumenty',
      en: 'Documents',
      ua: 'Документи',
    },
    link: '/client/documents/',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
        noIndex
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
        position='bottom'
      />
      <Main h={1} title={main.title[lang]} article={main.html[lang]}>
        <Events data={files} />
      </Main>
    </Layout>
  )
}

export default OchronaDanych
